import React from "react";
import {
  Button,
  Container,
  Typography,
  CssBaseline,
  Box,
  Grid,
  createTheme,
  ThemeProvider,
  Stack,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { SignInStatus } from "./states";

const theme = createTheme();

theme.typography.h3 = {
  fontSize: "1.8rem",
  [theme.breakpoints.up("md")]: {
    fontSize: "3.6rem",
  },
};

interface Props {
  signInStatus: SignInStatus;
}

const Home = (props: Props) => {
  return (
    <Container component="main" sx={{ mb: 2 }} maxWidth="lg">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src="./imgs/logo.png" alt="Readable" width="480" />
        <Typography
          variant="h5"
          component="h2"
          align="center"
          color="#84919e"
          sx={{ mt: 4, mb: 8 }}
        >
          英語のPDFファイルをレイアウトを保ったまま <br />
          日本語に翻訳
        </Typography>
        <img
          src="./imgs/home_catch.png"
          alt="英語のPDFファイルをレイアウトを保ったまま日本語に翻訳"
          style={{ maxWidth: 1000, width: "100%" }}
        />
        <ThemeProvider theme={theme}>
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            fontWeight="fontWeightMedium"
            sx={{ mt: 8, mb: 8 }}
          >
            PDFファイルを瞬時に翻訳
          </Typography>
        </ThemeProvider>
        <Grid container rowSpacing={8} columnSpacing={2}>
          <Grid
            item
            xs={12}
            lg={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="./imgs/home_img1.png"
              alt="デザインを崩さずに翻訳されたファイルのダウンロードが可能"
              width="350"
            />
            <Typography variant="h6" component="h2" gutterBottom align="center">
              デザインを崩さずに翻訳された
              <br />
              ファイルのダウンロードが可能
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="./imgs/home_img2.png"
              alt="翻訳前のファイルと翻訳後のファイルを見開きで確認が可能"
              width="350"
            />
            <Typography variant="h6" component="h2" gutterBottom align="center">
              翻訳前のファイルと翻訳後の
              <br />
              ファイルを見開きで確認が可能
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="./imgs/home_img3.png"
              alt="翻訳されたファイルが出来上がるまで約30秒"
              width="350"
            />
            <Typography variant="h6" component="h2" gutterBottom align="center">
              翻訳されたファイルが出来上がる
              <br />
              まで約30秒
            </Typography>
          </Grid>
        </Grid>
        {props.signInStatus === SignInStatus.INITIALSTATE && (
          <Box sx={{ height: 104 }} />
        )}
        {props.signInStatus === SignInStatus.NOTSIGNEDIN && (
          <Stack direction="row">
            <Button
              variant="contained"
              to="/translate"
              sx={{ mt: 8, width: 250, height: 40, mr: 8 }}
              component={RouterLink}
            >
              翻訳はこちらから（無料版）
            </Button>
            <Button
              variant="contained"
              to="/pricing"
              sx={{ mt: 8, width: 250, height: 40 }}
              component={RouterLink}
            >
              プロ会員登録（一週間無料）
            </Button>
          </Stack>
        )}
        {props.signInStatus === SignInStatus.VERYFING && (
          <Stack direction="row">
            <Button
              variant="contained"
              to="/translate"
              sx={{ mt: 8, width: 250, height: 40, mr: 8 }}
              component={RouterLink}
            >
              翻訳はこちらから（無料版）
            </Button>
            <Button
              variant="contained"
              size="large"
              to="/signup"
              sx={{ mt: 8, width: 250, height: 40 }}
              component={RouterLink}
            >
              確認メールの再送信
            </Button>
          </Stack>
        )}
        {props.signInStatus === SignInStatus.PURCHASING && (
          <Stack direction="row">
            <Button
              variant="contained"
              to="/translate"
              sx={{ mt: 8, width: 250, height: 40, mr: 8 }}
              component={RouterLink}
            >
              翻訳はこちらから（無料版）
            </Button>
            <Button
              variant="contained"
              size="large"
              to="/pricing"
              sx={{ mt: 8, width: 250, height: 40 }}
              component={RouterLink}
            >
              お支払い方法の登録
            </Button>
          </Stack>
        )}
        {props.signInStatus === SignInStatus.PREMIUM && (
          <Button
            variant="contained"
            to="/translate"
            sx={{ mt: 8, width: 200, height: 40 }}
            component={RouterLink}
          >
            翻訳はこちらから
          </Button>
        )}
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          align="center"
          sx={{ mt: 16 }}
        >
          プロ版の紹介
        </Typography>
        <iframe
          width="400"
          height="225"
          src="https://www.youtube.com/embed/XQOJsE4Rj2g"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Box>
    </Container>
  );
};

export default Home;
