export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

export const firebaseConfigDev: FirebaseConfig = {
  apiKey: "AIzaSyD9LTS4NsVGgzMRuMoyviH5HegJ6Fg7B2Y",
  authDomain: "readable-b36fb.firebaseapp.com",
  projectId: "readable-b36fb",
  storageBucket: "readable-b36fb.appspot.com",
  messagingSenderId: "517976967593",
  appId: "1:517976967593:web:7c47a0aa59eba0d66b5cb8",
};

export const firebaseConfigProd: FirebaseConfig = {
  apiKey: "AIzaSyB7QmBxGRiHXswGDpq70uTK_5LuWTMoeig",
  authDomain: "readable-product.firebaseapp.com",
  projectId: "readable-product",
  storageBucket: "readable-product.appspot.com",
  messagingSenderId: "1044592950861",
  appId: "1:1044592950861:web:3dd825091561e88d2de604",
};
