import React from "react";
import { Container, CssBaseline } from "@mui/material";

const Privacy = () => {
  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <h1>プライバシーポリシー</h1>
      <p>
        このプライバシーポリシー（以下「本プライバシーポリシー」といいます。）は、株式会社ネクストラボ（以下「当社」といいます。）が、当社の提供するサービス（以下「本サービス」といいます。）を利用される方（以下「利用者」といいます。）より提供を受ける個人情報の取り扱いにつき定めるものです。利用者は、本サービスの利用を開始した時点で、本プライバシーポリシーに同意したものと見做されます。
      </p>
      <h3>１．個人情報の取得、管理</h3>
      <p>
        当社は、本サービスの提供またはその他の方法により取得する利用者の氏名、メールアドレス、住所、電話番号、生年月日、クレジットカード情報を含む決済情報、身分証明書、その他の個人情報を、適正かつ公正な手段によって取得し、適正な管理を行うとともに、漏洩、紛失、改ざん等の防止のために最大限の注意を払います。
      </p>
      <h3>２．利用目的</h3>
      <p>当社は、利用者の個人情報を、以下の目的で利用いたします。</p>
      <ol>
        <li>
          本サービスまたは本サービスに関連するサービス（以下「関連サービス」といいます）の提供。
        </li>
        <li>
          本サービスまたは関連サービス、当社キャンペーン等にかかる案内、アンケートの実施。
        </li>
        <li>
          本サービスの改善、当社による新サービス、商品の開発、分析その他の調査、研究。
        </li>
        <li>本サービスの不正利用防止。</li>
        <li>有料サービス利用時等におけるご請求・決済処理。</li>
        <li>当社以外の事業者が広告主となる広告情報等の告知。</li>
        <li>
          個人を特定できないように処理を行った統計情報の作成、利用及び第三者提供。
        </li>
        <li>本サービスのメンテナンス、その他重要なお知らせ等の連絡。</li>
        <li>上記に付随、関連する業務、問い合わせ等への対応。</li>
      </ol>
      <h3>３．第三者への開示、提供</h3>
      <p>
        当社は、次の各号に掲げる事項のいずれかに該当する場合以外は、利用者の個人情報を事前の許可なく第三者へ開示または提供いたしません。
      </p>
      <ol>
        <li>
          本プライバシーポリシーに定めがある、または利用者本人の同意がある場合。
        </li>
        <li>法令に基づく場合。</li>
        <li>
          人の生命、身体または財産の保護のために必要な場合であって、利用者から同意を得ることが困難である場合。
        </li>
        <li>
          公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、利用者から同意を得ることが困難であるとき。
        </li>
        <li>
          国または地方公共団体等が公的な事務を実施するうえで、協力する必要がある場合であって、利用者から同意を得ることにより当該事務の遂行に支障が出るおそれがある場合。
        </li>
      </ol>
      <h3>４．業務の委託</h3>
      <p>
        当社は、本サービスを利用者に提供するにあたり、その業務の一部を第三者（日本国外の委託先を含みます。）に委託し、利用目的の達成に必要な範囲で当該業務委託先に対して個人情報を提供することがあります。この場合、当社は、適切に当該業務委託先の管理、監督を行います。
      </p>
      <h3>５．アクセス解析ツールについて</h3>
      <p>
        当社は、Googleによるアクセス解析ツール「Googleアナリティクス」を使用しています。このGoogleアナリティクスはデータの収集のためにCookieを使用しています。このデータは匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。この規約に関しての詳細は
        <a
          href="https://marketingplatform.google.com/about/analytics/terms/jp/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Googleアナリティクスサービス利用規約のページ
        </a>
        や
        <a
          href="https://policies.google.com/technologies/ads?hl=ja"
          target="_blank"
          rel="noopener noreferrer"
        >
          Googleポリシーと規約ページ
        </a>
        をご覧ください。
      </p>
      <h3>６．本プライバシーポリシーの変更</h3>
      <p>
        当社は、本プライバシーポリシーを変更することがあります。最新のプライバシーポリシーは本ページに掲載されます。掲載日をもって、新しいプライバシーポリシーが効力を生じるものとします。
      </p>
      <h3>７．個人情報の取り扱いに関するお問い合わせ</h3>
      <p>
        当社は、法令に基づき、利用者本人の個人情報に関する開示、訂正、削除、追加、利用停止、消去等に対応いたします。これらのご要望、お問い合わせは、下記までお願いいたします。
      </p>
      <p>
        株式会社ネクストラボ　プライバシーポリシー窓口
        <br />
        Email: read ＠ nextlabs.jp　＠を半角でお願いします。
        <br />
        件名：プライバシーポリシーに関して
      </p>
      <p>以上</p>
      <p>２０２２年１１月１１日　施行</p>
    </Container>
  );
};

export default Privacy;
