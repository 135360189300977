export const INITIALSTATE: number = -1;
export const NOTSIGNEDIN: number = 0;
export const VERYFING: number = 1;
export const PURCHASING: number = 2;
export const PREMIUM: number = 3;

export enum SignInStatus {
  INITIALSTATE = -1,
  NOTSIGNEDIN = 0,
  VERYFING = 1,
  PURCHASING = 2,
  PREMIUM = 3,
}
