import React, { useState, useRef, Fragment } from "react";
import {
  Button,
  Paper,
  ButtonGroup,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

interface Props {
  disable?: boolean;
  alFilepath: string;
  prFilepath: string;
}
const AltButton = (props: Props) => {
  const [openAlOptions, setOpenAlOptions] = useState(false);
  const anchorRefAlButton = useRef<any>(null);
  const handleToggleAlButton = () => {
    setOpenAlOptions((prevOpen) => !prevOpen);
  };

  const handleCloseAlButton = (event: any) => {
    if (
      anchorRefAlButton.current &&
      anchorRefAlButton.current.contains(event.target)
    ) {
      return;
    }
    setOpenAlOptions(false);
  };
  if (props.disable) {
    return (
      <ButtonGroup>
        <Button variant="contained" disabled>
          日・英 交互に表示された
          <br />
          PDF のダウンロード（見開き表示用）
        </Button>
        <Button size="small" variant="contained" disabled>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
    );
  }
  return (
    <Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRefAlButton}
        aria-label="split button"
      >
        <Button
          variant="contained"
          onClick={() => {
            window.open(
              process.env.REACT_APP_READABLE_FILE_URL + props.alFilepath,
              "_blank"
            );
          }}
        >
          日・英 交互に表示された
          <br />
          PDF のダウンロード（見開き表示用）
        </Button>
        <Button
          size="small"
          aria-controls={openAlOptions ? "split-button-menu" : undefined}
          aria-expanded={openAlOptions ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggleAlButton}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={openAlOptions}
        anchorEl={anchorRefAlButton.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseAlButton}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem
                    onClick={() => {
                      window.open(
                        process.env.REACT_APP_READABLE_FILE_URL +
                          props.prFilepath,
                        "_blank"
                      );
                    }}
                  >
                    プリンタ印刷用の交互 PDF
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default AltButton;
