import React from "react";
import { Container, Box, CssBaseline, Typography, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const NotFound = () => {
  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          fontWeight="fontWeightBold"
          sx={{ mb: 4 }}
        >
          ページが見つかりませんでした。
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
          }}
        >
          <Button
            variant="text"
            to="/"
            sx={{ mt: 4, height: 40 }}
            component={RouterLink}
          >
            トップに戻る
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default NotFound;
