import Free from "./free";
import Pro from "./pro";
import React from "react";
import { Container, CssBaseline } from "@mui/material";
import { INITIALSTATE, PREMIUM, SignInStatus } from "./states";

interface Props {
  signInStatus: SignInStatus;
  user: any;
  expire: number;
}

const Translate = (props: Props) => {
  if (props.signInStatus === INITIALSTATE) {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
      </Container>
    );
  }
  if (props.signInStatus !== PREMIUM) {
    return <Free />;
  }
  return (
    <Pro
      signInStatus={props.signInStatus}
      user={props.user}
      expire={props.expire}
    />
  );
};

export default Translate;
