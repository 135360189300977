import React, { useState, Fragment } from "react";
import {
  useSearchParams,
  useNavigate,
  Link as RouterLink,
} from "react-router-dom";
import {
  Link,
  Container,
  Box,
  CssBaseline,
  TextField,
  Grid,
  Button,
  Snackbar,
  Alert,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import firebase from "firebase/compat/app";
import { NOTSIGNEDIN, VERYFING, PURCHASING, PREMIUM } from "./states";

const plans: any =
  process.env.REACT_APP_READABLE_PRODUCT === "False"
    ? {
        monthly: "price_1MSD9rEhv4pZkJMeUran99tJ",
        yearly: "price_1MSD9rEhv4pZkJMeuMGZ2E0Y",
      }
    : {
        monthly: "price_1M9IRtEhv4pZkJMeBHvgd6AO",
        yearly: "price_1M9IRtEhv4pZkJMegZUG8Ftk",
      };

interface Props {
  handleSignUp: any;
}

const SignUpForm = (props: Props) => {
  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          fontWeight="fontWeightBold"
        >
          会員登録
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={props.handleSignUp}
          sx={{ mt: 3 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="メールアドレス"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="パスワード（６文字以上・文字と数字の組み合わせ）"
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "baseline",
            }}
          >
            <FormGroup>
              <FormControlLabel
                name="agree"
                control={<Checkbox />}
                label={
                  <Fragment>
                    <Link
                      to="/terms"
                      component={RouterLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      利用規約
                    </Link>
                    と
                    <Link
                      to="/privacy"
                      component={RouterLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      プライバシーポリシー
                    </Link>
                    に同意する
                  </Fragment>
                }
              />
            </FormGroup>
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            登録
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link variant="body2" to="/login" component={RouterLink}>
                ログインする
              </Link>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link variant="body2" to="/pricing" component={RouterLink}>
                プラン選択に戻る
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

interface VeryfingProps {
  user: any;
  handleResubimt: any;
}

const Veryfing = (props: VeryfingProps) => {
  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Typography
        component="h2"
        variant="body1"
        color="text.primary"
        align="center"
        sx={{ mt: 4 }}
      >
        {props.user.multiFactor.user.email} に確認メールを送信しました。
      </Typography>
      <Typography
        component="h2"
        variant="body1"
        color="text.primary"
        align="center"
        sx={{ mb: 2 }}
      >
        確認メールのリンクをクリックしてください。
      </Typography>
      <Typography
        component="h2"
        variant="body1"
        color="text.primary"
        align="center"
        sx={{ mb: 2 }}
      >
        受信できない場合は迷惑メールフォルダを探してみて下さい。
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "baseline",
        }}
      >
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={props.handleResubimt}
        >
          再送信
        </Button>
      </Box>
      {/* <Grid container justifyContent="flex-end">
                <Grid item>
                    <Link variant="body2" onClick={() => {setSignInStatus(SignUp)}}>
                        別のメールアドレスを使う
                    </Link>
                </Grid>
            </Grid> */}
    </Container>
  );
};

interface PurchasingProps {
  plan: any;
  db: any;
  user: any;
  handleTicket: any;
}

const Purchasing = (props: PurchasingProps) => {
  const [isLoading, setLoading] = useState(false);
  const plan = props.plan;
  const handleSubscribe = async (price: string) => {
    setLoading(true);
    const subscriptions = await props.db
      .collection("customers")
      .doc(props.user.uid)
      .collection("subscriptions")
      .get();
    const selectedPrice = {
      price: price,
      quantity: 1,
    };
    const checkoutSession = {
      automatic_tax: true,
      tax_id_collection: true,
      collect_shipping_address: true,
      line_items: [selectedPrice],
      success_url: process.env.REACT_APP_READABLE_URL + "thanks?plan=" + plan,
      cancel_url: process.env.REACT_APP_READABLE_URL + "signup?plan=" + plan,
      trial_from_plan: subscriptions.size === 0,
      allow_promotion_codes: true,
      metadata: {
        key: "value",
      },
    };

    const docRef = await props.db
      .collection("customers")
      .doc(props.user.uid)
      .collection("checkout_sessions")
      .add(checkoutSession);
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap: any) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and then inspect your function logs.
        alert(`An error occured: ${error.message}`);
        document
          .querySelectorAll("button")
          .forEach((b) => (b.disabled = false));
      }
      if (url) {
        window.location.assign(url);
      }
    });
  };
  if (plan === "ticket") {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Typography
          component="h2"
          variant="body1"
          color="text.primary"
          align="center"
          sx={{ mt: 4 }}
        >
          {props.user.multiFactor.user.email} を認証しました。
        </Typography>
        <Typography
          component="h2"
          variant="body1"
          color="text.primary"
          align="center"
          sx={{ mt: 1 }}
        >
          アクティベーションコードを入力してください。
        </Typography>
        <Typography
          component="h2"
          variant="body1"
          color="text.primary"
          align="center"
          sx={{ mt: 1 }}
        >
          アクティベーションコードは AB98CDEF7G のような 英数字 10
          文字のコードです。
        </Typography>
        {plan === "yearly" && (
          <Typography
            component="h2"
            variant="body1"
            color="text.primary"
            align="center"
            sx={{ mt: 1 }}
          >
            年額プランを選択しています。
          </Typography>
        )}
        <Box
          component="form"
          noValidate
          onSubmit={props.handleTicket}
          sx={{ mt: 2 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="ticket"
                label="アクティベーションコード"
                name="ticket"
                sx={{ mt: 2, mb: 1 }}
              />
            </Grid>
          </Grid>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            sx={{ mt: 1, mb: 1 }}
          >
            送信
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
          }}
        >
          <Button
            variant="text"
            sx={{ mt: 1, mb: 2 }}
            to="/pricing"
            component={RouterLink}
          >
            クレジットカードでサブスクリプションに申し込む
          </Button>
        </Box>
      </Container>
    );
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Typography
        component="h2"
        variant="body1"
        color="text.primary"
        align="center"
        sx={{ mt: 4 }}
      >
        {props.user.multiFactor.user.email} を認証しました。
      </Typography>
      {plan === "monthly" && (
        <Typography
          component="h2"
          variant="body1"
          color="text.primary"
          align="center"
          sx={{ mt: 1 }}
        >
          月額プランを選択しています。
        </Typography>
      )}
      {plan === "yearly" && (
        <Typography
          component="h2"
          variant="body1"
          color="text.primary"
          align="center"
          sx={{ mt: 1 }}
        >
          年額プランを選択しています。
        </Typography>
      )}
      {plan !== "monthly" && plan !== "yearly" && (
        <Typography
          component="h2"
          variant="body1"
          color="text.primary"
          align="center"
          sx={{ mt: 1 }}
        >
          プラン情報を読み込めませんでした。プランを選びなおしてください。
        </Typography>
      )}
      {(plan === "monthly" || plan === "yearly") && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 4, mb: 1 }}
            onClick={() => {
              handleSubscribe(plans[plan]);
            }}
          >
            お支払い方法の登録に進む
          </Button>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "baseline",
        }}
      >
        <Button
          variant="text"
          sx={{ mt: 1, mb: 2 }}
          to="/pricing"
          component={RouterLink}
        >
          プランを選びなおす
        </Button>
      </Box>
      {isLoading && (
        <Fragment>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Fragment>
      )}
    </Container>
  );
};

const Premium = () => {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Typography
        component="h2"
        variant="body1"
        color="text.primary"
        align="center"
        sx={{ mt: 4 }}
      >
        既に登録済みです。
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "baseline",
        }}
      >
        <Button
          variant="contained"
          to="/translate"
          sx={{ mt: 4, width: 200, height: 40 }}
          component={RouterLink}
        >
          翻訳はこちらから
        </Button>
      </Box>
    </Container>
  );
};

interface SignUpProps {
  user: any;
  db: any;
  signInStatus: number;
  setSignInStatus: any;
  setExpire: any;
}

const SignUp = (props: SignUpProps) => {
  const [showSnack, setShowSnack] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState("error");
  const [snackMessage, setSnackMessage] = useState<any>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const plan = searchParams.get("plan");
  const handleSignUp = (e: any) => {
    e.preventDefault();
    if (!e.target.agree.checked) {
      setSnackMessage("利用規約への同意が必要です。");
      setSnackSeverity("error");
      setShowSnack(true);
      return;
    }
    const email = e.target.email.value;
    const password = e.target.password.value;
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        // @ts-ignore
        user.sendEmailVerification({
          url: process.env.REACT_APP_READABLE_URL + "signup?plan=" + plan,
        });
      })
      .catch((error) => {
        if (error.code === "auth/invalid-email") {
          setSnackMessage("メールアドレスが無効です。");
        } else if (error.code === "auth/weak-password") {
          setSnackMessage("パスワードが弱すぎます。");
        } else if (error.code === "auth/email-already-in-use") {
          setSnackMessage(
            "既に登録されているメールアドレスです。ログインしてください。"
          );
        } else {
          setSnackMessage(
            "登録に失敗しました。正しい情報を入力しているか確認してください。"
          );
        }
        setSnackSeverity("error");
        setShowSnack(true);
      });
  };
  const handleResubimt = () => {
    if (props.user) {
      // @ts-ignore
      firebase
        .auth()
        .currentUser.reload()
        .then(() => {
          // @ts-ignore
          if (firebase.auth().currentUser.emailVerified) {
            setSnackMessage("既に認証済みです。");
            setSnackSeverity("error");
            setShowSnack(true);
          } else {
            props.user
              .sendEmailVerification({
                url: process.env.REACT_APP_READABLE_URL + "signup?plan=" + plan,
              })
              .then(() => {
                setSnackMessage("確認メールを再送しました。");
                setSnackSeverity("info");
                setShowSnack(true);
              })
              .catch((e: any) => {
                if (e.code === "auth/too-many-requests") {
                  setSnackMessage(
                    "前回送信時からの間隔が短すぎます。1 分以上お待ちいただき再度お試しください。"
                  );
                  setSnackSeverity("error");
                  setShowSnack(true);
                } else {
                  setSnackMessage("エラーが発生しました。");
                  setSnackSeverity("error");
                  setShowSnack(true);
                }
              });
          }
        });
    }
  };
  const handleTicket = (e: any) => {
    e.preventDefault();
    const ticket = e.target.ticket.value;
    axios
      .post(process.env.REACT_APP_READABLE_BACKEND_URL + "ticketauth/", {
        ticket: ticket,
        uid: props.user.uid,
      })
      .then((response) => {
        if (response.status !== 200) {
          setSnackMessage("予期せぬエラーが発生しました。");
          setSnackSeverity("error");
          setShowSnack(true);
        } else if ("invalid" in response.data) {
          setSnackMessage(
            "アクティベーションコードが正しくありません。アクティベーションコードは AB98CDEF7G のような英数字 10 文字のコードです。入力に間違いが無いかご確認ください。問題が解決しない場合はお問い合わせまでご連絡ください。※アクティベーションコードとクーポンコードは別物です。クーポンコードをお持ちの方は、「クレジットカードでサブスクリプションに申し込む」をクリックして、月額または年額会員にご登録ください。お支払い方法の登録画面にて左側にクーポンコードを入力する欄がございます。"
          );
          setSnackSeverity("error");
          setShowSnack(true);
        } else if ("used" in response.data) {
          setSnackMessage(
            "使用済みのアクティベーションコードです。問題が解決しない場合はお問い合わせまでご連絡ください。"
          );
          setSnackSeverity("error");
          setShowSnack(true);
        } else if ("ok" in response.data) {
          props.setSignInStatus(PREMIUM);
          props.setExpire(response.data.expire);
          navigate("/thanks?plan=ticket");
        } else {
          setSnackMessage("予期せぬエラーが発生しました。");
          setSnackSeverity("error");
          setShowSnack(true);
        }
      })
      .catch((e) => {
        setSnackMessage("予期せぬエラーが発生しました。");
        setSnackSeverity("error");
        setShowSnack(true);
      });
  };
  return (
    <Fragment>
      {props.signInStatus === NOTSIGNEDIN && (
        <SignUpForm handleSignUp={handleSignUp} />
      )}
      {props.signInStatus === VERYFING && (
        <Veryfing user={props.user} handleResubimt={handleResubimt} />
      )}
      {props.signInStatus === PURCHASING && (
        <Purchasing
          user={props.user}
          db={props.db}
          plan={plan}
          handleTicket={handleTicket}
        />
      )}
      {props.signInStatus === PREMIUM && <Premium />}
      <Snackbar
        open={showSnack}
        autoHideDuration={30000}
        onClose={() => setShowSnack(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowSnack(false)}
          // @ts-ignore
          severity={snackSeverity}
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default SignUp;
