import * as React from "react";
import { Link, Container, Box, Typography, Divider, Grid } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      © NextLab Inc. All Rights Reserved.
    </Typography>
  );
}

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
        backgroundColor: "#fcfcfc",
      }}
    >
      <Divider sx={{ mt: 4, mb: 4 }} />
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          {/* @ts-ignore */}
          <Grid item xs={12} lg={1.5} align="center">
            <Link
              to="/"
              color="inherit"
              underline="hover"
              component={RouterLink}
            >
              ホーム
            </Link>
          </Grid>
          {/* @ts-ignore */}
          <Grid item xs={12} lg={2} align="center">
            <Link
              href="https://share.hsforms.com/1YhlfLWLgSmWYdjXPkTt8XAe4idn "
              color="inherit"
              underline="hover"
              target="_blank"
              rel="noreferrer"
            >
              お問い合わせ
            </Link>
          </Grid>
          {/* @ts-ignore */}
          <Grid item xs={12} lg={2} align="center">
            <Link
              href="https://readable-pdf.notion.site/f4d47a1756f5486d8b19cd51657d9226"
              color="inherit"
              underline="hover"
              sx={{ ml: 1, mr: 1 }}
              target="_blank"
              rel="noreferrer"
            >
              よくあるご質問
            </Link>
          </Grid>
          {/* @ts-ignore */}
          <Grid item xs={12} lg={2.5} align="center">
            <Link
              to="/privacy"
              color="inherit"
              underline="hover"
              component={RouterLink}
            >
              プライバシーポリシー
            </Link>
          </Grid>
          {/* @ts-ignore */}
          <Grid item xs={12} lg={1.5} align="center">
            <Link
              to="/terms"
              color="inherit"
              underline="hover"
              component={RouterLink}
            >
              利用規約
            </Link>
          </Grid>
          {/* @ts-ignore */}
          <Grid item xs={12} lg={2.5} align="center">
            <Link
              to="/tokutei"
              color="inherit"
              underline="hover"
              component={RouterLink}
            >
              特定商取引に関する表記
            </Link>
          </Grid>
        </Grid>
        <Box sx={{ mt: 4, mb: 2 }}>
          <Copyright />
        </Box>
      </Container>
    </Box>
  );
}
