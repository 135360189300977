import React from "react";
import { Container, CssBaseline } from "@mui/material";

const Tokutei = () => {
  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <h1>特定商取引法に基づく表記</h1>
      <h3>サービス名</h3>
      <p>Readable</p>
      <h3>法人名</h3>
      <p>株式会社ネクストラボ</p>
      <h3>所在地</h3>
      <p>東京都渋谷区渋谷2-19-15 宮益坂ビルディング609</p>
      <h3>電話番号</h3>
      <p>050-3101-2748</p>
      <h3>メールアドレス</h3>
      <p>Email: read ＠ nextlabs.jp　＠を半角でお願いします。</p>
      <h3>取締役</h3>
      <p>亀井智英</p>
      <h3>利用料</h3>
      <p>月額プランは月 980 円、年額プランは年 9800 円。</p>
      <h3>追加手数料</h3>
      <p>
        サイト閲覧、コンテンツのダウンロード、お問い合わせなどの際に必要となるインターネット接続料金、通信料等。
      </p>
      <h3>お支払い方法</h3>
      <p>クレジットカード決済</p>
      <h3>返品・交換・キャンセル等</h3>
      <p>
        電子商品としての性質上、返品およびキャンセルできかねます。あらかじめ、提供サービスの内容、動作環境等をよくお確かめの上ご購入ください。
      </p>
      <h3>利用開始時間</h3>
      <p>ご購入後ただちに利用開始できます。</p>
      <h3>決済期間</h3>
      <p>クレジットカード決済の場合はただちに処理されます。</p>
    </Container>
  );
};

export default Tokutei;
