import Header from "./header";
import Footer from "./footer";
import Home from "./home";
import SignUp from "./signup";
import LogIn from "./login";
import Pricing from "./pricing";
import Translate from "./translate";
import NotFound from "./notfound";
import Verify from "./verify";
import Reset from "./reset";
import Terms from "./terms";
import Privacy from "./privacy";
import Tokutei from "./tokutei";
import Thanks from "./thanks";
import { SignInStatus } from "./states";
import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";
import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import {
  FirebaseConfig,
  firebaseConfigDev,
  firebaseConfigProd,
} from "./firebase/config";

const firebaseConfig: FirebaseConfig =
  process.env.REACT_APP_READABLE_PRODUCT === "False"
    ? firebaseConfigDev
    : firebaseConfigProd;

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();

const App = () => {
  const [signInStatus, setSignInStatus] = useState(SignInStatus.INITIALSTATE);
  const [user, setUser] = useState<any>();
  const [accessToken, setAccessToken] = useState("INITIALSTATE");
  const [expire, setExpire] = useState(0);
  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user) {
          setUser(user);
          if (user.emailVerified) {
            db.collection("customers")
              .doc(user.uid)
              .collection("subscriptions")
              .where("status", "in", ["trialing", "active"])
              .get()
              .then((subscriptionSnapshot) => {
                if (subscriptionSnapshot.empty) {
                  db.collection("customers")
                    .doc(user.uid)
                    .collection("tickets")
                    .get()
                    .then((ticketSnapshot) => {
                      let expire = 0;
                      for (let doc of ticketSnapshot.docs) {
                        if (doc.data().expire.seconds * 1000 > Date.now()) {
                          expire = doc.data().expire.seconds;
                        }
                      }
                      if (expire !== 0) {
                        setSignInStatus(SignInStatus.PREMIUM);
                        // @ts-expect-error
                        setAccessToken(user.multiFactor.user.accessToken);
                        setExpire(expire);
                      } else {
                        setSignInStatus(SignInStatus.PURCHASING);
                        setAccessToken("none");
                      }
                    });
                } else {
                  setSignInStatus(SignInStatus.PREMIUM);
                  // @ts-expect-error
                  setAccessToken(user.multiFactor.user.accessToken);
                  setExpire(0);
                }
              });
          } else {
            setSignInStatus(SignInStatus.VERYFING);
            setAccessToken("none");
          }
        } else {
          setSignInStatus(SignInStatus.NOTSIGNEDIN);
          setAccessToken("none");
        }
      });
    return () => {
      unregisterAuthObserver();
    };
  }, []);
  useEffect(() => {
    if (accessToken === "INITIALSTATE") {
      return () => {};
    }
    function notification() {
      window.postMessage({ accessToken }, "*");
    }
    const notificationIntervalId = setInterval(notification, 1000);
    return () => {
      clearInterval(notificationIntervalId);
    };
  }, [accessToken]);
  useEffect(() => {
    axios.post(
      process.env.REACT_APP_READABLE_BACKEND_URL + "set-cookie/",
      {},
      { withCredentials: true }
    );
  }, []);
  return (
    <BrowserRouter>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Header signInStatus={signInStatus} ticketPremium={expire !== 0} />
        <Routes>
          <Route path="/" element={<Home signInStatus={signInStatus} />} />
          <Route
            path="/login"
            element={<LogIn signInStatus={signInStatus} />}
          />
          <Route
            path="/signup"
            element={
              <SignUp
                signInStatus={signInStatus}
                user={user}
                db={db}
                setSignInStatus={setSignInStatus}
                setExpire={setExpire}
              />
            }
          />
          <Route
            path="/pricing"
            element={<Pricing signInStatus={signInStatus} />}
          />
          <Route
            path="/translate"
            element={
              <Translate
                signInStatus={signInStatus}
                user={user}
                expire={expire}
              />
            }
          />
          <Route
            path="/verify"
            element={
              <Verify
                signInStatus={signInStatus}
                setSignInStatus={setSignInStatus}
              />
            }
          />
          <Route path="/reset" element={<Reset />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/tokutei" element={<Tokutei />} />
          <Route path="/thanks" element={<Thanks />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Box>
    </BrowserRouter>
  );
};

export default App;
