import React, { useState, Fragment, useEffect } from "react";
import {
  useNavigate,
  useSearchParams,
  Link as RouterLink,
} from "react-router-dom";
import {
  Container,
  Box,
  CssBaseline,
  TextField,
  Button,
  Snackbar,
  Alert,
  Typography,
} from "@mui/material";
import firebase from "firebase/compat/app";
import { SignInStatus } from "./states";

interface Props {
  continueUrl: string;
  setSignInStatus: (signInStatus: SignInStatus) => void;
  oobCode: string;
}

const VerifyEmail = ({ oobCode, continueUrl, setSignInStatus }: Props) => {
  const [failed, setFailed] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    firebase
      .auth()
      .applyActionCode(oobCode)
      .then(() => {
        const intervalId = setInterval(() => {
          // @ts-ignore
          firebase.auth().currentUser.reload();
          // @ts-ignore
          if (firebase.auth().currentUser.emailVerified) {
            if (continueUrl) {
              const url = new URL(continueUrl);
              const params = url.searchParams;
              let plan = params.get("plan");
              setSignInStatus(SignInStatus.PURCHASING);
              clearInterval(intervalId);
              navigate("/signup?plan=" + plan);
            }
          }
        }, 1000);
        return () => clearInterval(intervalId);
      })
      .catch(() => {
        setFailed(true);
      });
  }, [oobCode, continueUrl, setSignInStatus, navigate]);
  if (failed) {
    return (
      // @ts-ignore
      <Container component="main" maxWidth="xs" align="center" sx={{ mt: 4 }}>
        認証に失敗しました。
      </Container>
    );
  }
  return (
    // @ts-ignore
    <Container component="main" maxWidth="xs" align="center" sx={{ mt: 4 }}>
      認証中です...
    </Container>
  );
};

interface ResetPasswordProps {
  oobCode: string;
}

const ResetPassword = (props: ResetPasswordProps) => {
  const [showSnack, setShowSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState<any>();
  const [failed, setFailed] = useState(false);
  const [succeed, setSucceed] = useState(false);
  const [email, setEmail] = useState();
  useEffect(() => {
    firebase
      .auth()
      .verifyPasswordResetCode(props.oobCode)
      .then((email) => {
        // @ts-ignore
        setEmail(email);
      })
      .catch(() => {
        setFailed(true);
      });
  }, [props.oobCode]);
  if (failed) {
    return (
      // @ts-ignore
      <Container component="main" maxWidth="xs" align="center" sx={{ mt: 4 }}>
        パスワード設定の認証に失敗しました。
      </Container>
    );
  }
  if (succeed) {
    return (
      // @ts-ignore
      <Container component="main" maxWidth="xs" align="center">
        <Typography
          component="h2"
          variant="body1"
          color="text.primary"
          align="center"
          sx={{ mt: 4 }}
        >
          パスワードのリセットに成功しました。
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
          }}
        >
          <Button
            variant="text"
            to="/login"
            sx={{ mt: 4 }}
            component={RouterLink}
          >
            ログイン
          </Button>
        </Box>
      </Container>
    );
  }
  if (!email) {
    return (
      // @ts-ignore
      <Container component="main" maxWidth="xs" align="center" sx={{ mt: 4 }}>
        パスワード設定の認証中...
      </Container>
    );
  }
  const handleReset = (e: any) => {
    e.preventDefault();
    const password = e.target.password.value;
    firebase
      .auth()
      .confirmPasswordReset(props.oobCode, password)
      .then(() => {
        setSucceed(true);
      })
      .catch((error) => {
        if (error.code === "auth/weak-password") {
          setSnackMessage("パスワードが弱すぎます。");
        } else {
          setSnackMessage("エラーが発生しました。");
        }
        setShowSnack(true);
      });
  };
  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          fontWeight="fontWeightBold"
        >
          パスワードのリセット
        </Typography>
        <Typography
          variant="body1"
          component="h1"
          gutterBottom
          fontWeight="fontWeightBold"
        >
          {email}
        </Typography>
        <Box component="form" noValidate onSubmit={handleReset} sx={{ mt: 3 }}>
          <TextField
            required
            fullWidth
            name="password"
            label="パスワード（６文字以上・文字と数字の組み合わせ）"
            type="password"
            id="password"
            autoComplete="new-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            登録
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={showSnack}
        autoHideDuration={6000}
        onClose={() => setShowSnack(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowSnack(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

interface VerifyProps {
  signInStatus: SignInStatus;
  setSignInStatus: (signInStatus: SignInStatus) => void;
}

const Verify = (props: VerifyProps) => {
  const [searchParams] = useSearchParams();
  const mode = searchParams.get("mode");
  const oobCode = searchParams.get("oobCode");
  const continueUrl = searchParams.get("continueUrl");
  if (!mode) {
    return (
      // @ts-ignore
      <Container component="main" maxWidth="xs" align="center" sx={{ mt: 4 }}>
        無効なリクエストです。
      </Container>
    );
  }
  if (props.signInStatus === SignInStatus.INITIALSTATE) {
    return (
      // @ts-ignore
      <Container component="main" maxWidth="xs" align="center" sx={{ mt: 4 }}>
        <CssBaseline />
      </Container>
    );
  }
  if (
    mode === "verifyEmail" &&
    props.signInStatus === SignInStatus.NOTSIGNEDIN
  ) {
    return (
      // @ts-ignore
      <Container component="main" maxWidth="xs" align="center" sx={{ mt: 4 }}>
        ログインしてください。
      </Container>
    );
  }
  if (
    mode === "verifyEmail" &&
    [SignInStatus.PURCHASING, SignInStatus.PREMIUM].includes(props.signInStatus)
  ) {
    return (
      // @ts-ignore
      <Container component="main" maxWidth="xs" align="center" sx={{ mt: 4 }}>
        認証済みです。
      </Container>
    );
  }
  return (
    <Fragment>
      {/* @ts-ignore */}
      {mode === "resetPassword" && <ResetPassword oobCode={oobCode} />}
      {mode === "verifyEmail" && (
        <VerifyEmail
          // @ts-ignore
          oobCode={oobCode}
          // @ts-ignore
          continueUrl={continueUrl}
          setSignInStatus={props.setSignInStatus}
        />
      )}
    </Fragment>
  );
};

export default Verify;
