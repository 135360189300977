import React, { useState, useRef, Fragment, ChangeEvent } from "react";
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";
import {
  Button,
  Container,
  Stack,
  Typography,
  TextField,
  CircularProgress,
  Alert,
  CssBaseline,
  Box,
  Snackbar,
  Link,
  Paper,
} from "@mui/material";
import AltButton from "./altButton";

const REACT_APP_READABLE_FILE_URL: string =
  process.env.REACT_APP_READABLE_FILE_URL!;

const Free = () => {
  const inputRef = useRef<any>(null);
  const [showSnack, setShowSnack] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState("error");
  const [snackMessage, setSnackMessage] = useState<any>();
  const [url, setURL] = useState();
  const [state, setState] = useState(0);
  const [originalFilename, setOriginalFilename] = useState("");
  const [UUID, setUUID] = useState(0);
  const [jaFilepath, setJaFilepath] = useState<string>();
  const [alFilepath, setAlFilepath] = useState<string>();
  const [prFilepath, setPrFilepath] = useState<string>();
  const [coupon, setCoupon] = useState();
  const [reported, setReported] = useState(false);
  const sendMessageToBackend = (message: string) => {
    axios.post(
      process.env.REACT_APP_READABLE_BACKEND_URL + "log?message=" + message
    );
  };
  const processFile = async (source: File) => {
    setState(1);
    setReported(false);
    setOriginalFilename(source.name);
    const params = new FormData();
    params.append("file", source);
    axios
      .post(process.env.REACT_APP_READABLE_BACKEND_URL + "toquery/", params, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status !== 200) {
          setSnackMessage(
            "予期せぬエラーが発生しました。お送りいただいた文書が Readable に対応していない可能性があります。"
          );
          setSnackSeverity("error");
          setShowSnack(true);
          setState(0);
        } else if ("sizeover" in response.data) {
          setSnackMessage(
            <Fragment>
              ファイルが大きすぎます。フリー版では 20 ページ以下かつ 20 MB
              未満のファイルのみご利用いただけます。大きなファイルにつきましては
              <Link
                to="/pricing"
                component={RouterLink}
                onClick={() => sendMessageToBackend("click_sizeover")}
              >
                プロ版
              </Link>
              の利用をご検討ください。
            </Fragment>
          );
          setSnackSeverity("error");
          setShowSnack(true);
          setState(0);
          sendMessageToBackend("show_sizeover");
        } else if ("undefined" in response.data) {
          setState(0);
        } else if ("error" in response.data) {
          setSnackMessage(
            "予期せぬエラーが発生しました。お送りいただいた文書が Readable に対応していない可能性があります。"
          );
          setSnackSeverity("error");
          setShowSnack(true);
          setState(0);
        } else if ("notext" in response.data) {
          setSnackMessage(
            "テキストが検出されませんでした。PDF ファイルのテキストをマウスでドラッグするなどしてテキストが選択できるか・埋め込まれているかを確認してください。PDF を分割した場合、分割の過程でテキスト情報が失われた可能性があります。適切に分割してください。元のファイルにテキスト情報がない場合は Readable は利用できません。断念するか、テキストが埋め込まれている同等のファイル・論文を見つけて利用ください。"
          );
          setSnackSeverity("error");
          setShowSnack(true);
          setState(0);
        } else if ("notpdf" in response.data) {
          setSnackMessage("PDF ファイルにのみ対応しております。");
          setState(0);
          setShowSnack(true);
        } else {
          setUUID(response.data.uuid);
          setURL(response.data.url);
          if (decodeURIComponent(response.data.url).length >= 3040) {
            setSnackMessage(
              <Fragment>
                文字数が 3000 字を越えているため、DeepL Free
                をお使いの方は文字数超過する可能性があります（DeepL Pro
                をお使いの方は問題ありません）。長いファイルを翻訳する場合は
                <Link
                  to="/pricing"
                  component={RouterLink}
                  onClick={() => sendMessageToBackend("click_5000")}
                >
                  Readable プロ版
                </Link>
                の利用をご検討ください。
              </Fragment>
            );
            setSnackSeverity("warning");
            setShowSnack(true);
            sendMessageToBackend("show_5000");
          }
          setState(2);
          if ("coupon" in response.data) {
            setCoupon(response.data.coupon);
          }
        }
      })
      .catch((e) => {
        if (e.response.status === 413) {
          setSnackMessage(
            <Fragment>
              ファイルが大きすぎます。フリー版では 20 ページ以下かつ 20 MB
              未満のファイルのみご利用いただけます。大きなファイルにつきましては
              <Link
                to="/pricing"
                component={RouterLink}
                onClick={() => sendMessageToBackend("click_sizeover")}
              >
                プロ版
              </Link>
              の利用をご検討ください。
            </Fragment>
          );
          setSnackSeverity("error");
          setShowSnack(true);
          setState(0);
          sendMessageToBackend("show_sizeover");
        } else {
          setSnackMessage(
            "予期せぬエラーが発生しました。お送りいただいた文書が Readable に対応していない可能性があります。"
          );
          setSnackSeverity("error");
          setShowSnack(true);
          setState(0);
        }
      });
  };
  const onDrop = (e: DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    // @ts-ignore
    const files = e.dataTransfer.files;
    console.log(files);
    if ((state === 0 || state >= 2) && files.length === 1 && files[0]) {
      processFile(files[0]);
    }
  };
  const onDragOver = (e: DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const onFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    const source = e.target.files[0];
    if (!source) {
      return;
    }
    processFile(source);
  };
  const onClickPost = async () => {
    setState(3);
    // @ts-ignore
    const body = document.getElementById("text").value;
    axios
      .post(process.env.REACT_APP_READABLE_BACKEND_URL + "generate/", {
        body: body,
        uuid: UUID,
        original_filename: originalFilename,
      })
      .then((response) => {
        if (response.status !== 200) {
          setSnackMessage(
            "予期せぬエラーが発生しました。お送りいただいた文書が Readable に対応していない可能性があります。"
          );
          setSnackSeverity("error");
          setShowSnack(true);
          setState(2);
        } else if ("shorter" in response.data) {
          setSnackMessage(
            "エラー：翻訳結果を正しくペーストしているか確認してください。"
          );
          setSnackSeverity("error");
          setShowSnack(true);
          setState(2);
        } else if ("error" in response.data) {
          setSnackMessage(
            "予期せぬエラーが発生しました。お送りいただいた文書が Readable に対応していない可能性があります。"
          );
          setSnackSeverity("error");
          setShowSnack(true);
          setState(2);
        } else {
          setJaFilepath(response.data.ja);
          setAlFilepath(response.data.alt);
          setPrFilepath(response.data.print);
          setState(4);
        }
      })
      .catch((e) => {
        setSnackMessage(
          "予期せぬエラーが発生しました。お送りいただいた文書が Readable に対応していない可能性があります。"
        );
        setSnackSeverity("error");
        setShowSnack(true);
        setState(2);
      });
  };
  const onClickReport = () => {
    setReported(true);
    axios.post(
      process.env.REACT_APP_READABLE_BACKEND_URL + "report?uuid=" + UUID
    );
  };
  return (
    <Fragment>
      {/* @ts-ignore */}
      <Container
        component="main"
        sx={{ mb: 2 }}
        maxWidth="lg"
        onDragOver={onDragOver}
        onDrop={onDrop}
      >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            component="h1"
            fontWeight="fontWeightBold"
            sx={{ mb: 2 }}
          >
            1.翻訳したいPDFをアップロード
          </Typography>
          <img src="./imgs/upload.png" alt="アップロード" width="200" />
          {(state === 0 || state >= 2) && (
            <Button
              variant="contained"
              sx={{ mt: 2, mb: 4, width: 200, height: 40 }}
              onClick={() => {
                inputRef.current.click();
              }}
            >
              PDFのアップロード
            </Button>
          )}
          {state === 1 && (
            <CircularProgress sx={{ mt: 2, mb: 4, height: 40 }} />
          )}
          <Typography
            variant="subtitle2"
            component="h2"
            align="center"
            color="#84919e"
            sx={{ mb: 8 }}
          >
            DeepL Pro をお使いの方は ~20 ページを目安に利用してください。
            <br />
            DeepL Free をお使いの方は 1 ページずつ利用してください。
          </Typography>
          <Typography
            variant="h4"
            component="h1"
            fontWeight="fontWeightBold"
            sx={{ mb: 4 }}
          >
            2.DeepLを開いて右側の翻訳結果をコピー
          </Typography>
          {state !== 2 && (
            <Button
              disabled
              variant="contained"
              sx={{ mt: 2, mb: 4, width: 200, height: 40 }}
            >
              DeepL を開く
            </Button>
          )}
          {state === 2 && (
            // @ts-ignore
            <Button
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              variant="contained"
              sx={{ mt: 2, mb: 4, width: 200, height: 40 }}
            >
              DeepL を開く
            </Button>
          )}
          <img
            src="./imgs/deepl.png"
            alt="DeepLを開いて右側の翻訳結果をコピー"
            style={{ maxWidth: 600, width: "100%" }}
          />
          <TextField
            sx={{ mt: 4, maxWidth: 600, width: "100%z" }}
            id="text"
            multiline
            rows={5}
            label="翻訳結果の貼り付け"
            variant="outlined"
          />
          {state < 2 && (
            <Button
              disabled
              variant="contained"
              sx={{ mt: 2, mb: 8, width: 200, height: 40 }}
            >
              貼り付け完了
            </Button>
          )}
          {state === 2 && (
            <Button
              onClick={onClickPost}
              variant="contained"
              sx={{ mt: 2, mb: 8, width: 200, height: 40 }}
            >
              貼り付け完了
            </Button>
          )}
          {state >= 4 && (
            <Button
              variant="contained"
              disabled
              sx={{ mt: 2, mb: 8, width: 200, height: 40 }}
            >
              完了
            </Button>
          )}
          {state === 3 && (
            <CircularProgress sx={{ mt: 2, mb: 8, height: 40 }} />
          )}
          <Typography
            variant="h4"
            component="h1"
            fontWeight="fontWeightBold"
            sx={{ mb: 4 }}
          >
            3.翻訳結果をダウンロード
          </Typography>
          {state < 4 && (
            <img
              src="./imgs/download_disable.png"
              alt="翻訳結果をダウンロード"
              width="300"
            />
          )}
          {state >= 4 && (
            <img
              src="./imgs/download.png"
              alt="翻訳結果をダウンロード"
              width="300"
            />
          )}
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ mt: 4 }}
          >
            {state < 4 && (
              <Button variant="contained" disabled>
                日本語 PDF のダウンロード
              </Button>
            )}
            {state >= 4 && (
              <Button
                variant="contained"
                onClick={() => {
                  window.open(
                    REACT_APP_READABLE_FILE_URL + jaFilepath,
                    "_blank"
                  );
                }}
              >
                日本語 PDF のダウンロード
              </Button>
            )}
            <AltButton
              disable={state < 4}
              // @ts-ignore
              alFilepath={alFilepath}
              // @ts-ignore
              prFilepath={prFilepath}
            />
          </Stack>
          {(state < 4 || reported) && (
            <Button
              disabled
              variant="text"
              sx={{ mt: 1, ml: 50, width: 220, height: 40 }}
            >
              {reported && "ご協力ありがとうございます"}
              {!reported && "レイアウト崩れを報告"}
            </Button>
          )}
          {state >= 4 && !reported && (
            <Button
              variant="text"
              onClick={onClickReport}
              sx={{ mt: 1, ml: 50, width: 220, height: 40 }}
            >
              レイアウト崩れを報告
            </Button>
          )}
          {coupon && (
            // @ts-ignore
            <Paper
              variant="outlined"
              severity="success"
              align="center"
              sx={{ mt: 8, pt: 1, pb: 1, pl: 2, pr: 2 }}
            >
              <Typography sx={{ fontWeight: "bold", color: "#1976d2" }}>
                980 円引きクーポンコード "{coupon}" を獲得しました！
              </Typography>
              <Typography>
                月額プランで登録すれば 1 週間 + 1 ヶ月無料！
              </Typography>
              <Typography>
                年額プランでは 10% 引きでご利用いただけます。
              </Typography>
              <Typography>
                ページを更新するとコードは消えるのでメモをお忘れなく。
              </Typography>
              <Typography>
                利用方法：
                <Link to="/pricing" component={RouterLink}>
                  プロ会員登録
                </Link>
                より登録へ進む
              </Typography>
              <Typography>→ 会員登録を行う</Typography>
              <Typography>→ お支払い方法の登録に進む</Typography>
              <Typography>
                → 画面左側の「プロモーションコードを追加」に記入してください。
              </Typography>
              <Typography>
                アクティベーションコードとは別物なので注意してください。
              </Typography>
              <Typography>使用期限は 3 日間です。</Typography>
            </Paper>
          )}
          <Paper variant="outlined" sx={{ mt: 4, mb: 3, p: 3, width: 600 }}>
            <Typography component="h4">
              交互 PDF がおすすめです。交互 PDF
              は日本語のページと英語のページが交互に現れます。Chrome の PDF
              ビューワーであれば、右上の三点ボタンから 2
              ページ表示を選んで見開き表示してください。
            </Typography>
            <Typography component="h4">
              プリンタ印刷用の交互 PDF
              では冒頭に空ページが挿入されています。プリンタで両面印刷すると見開きで読むことができます。
            </Typography>
          </Paper>
          {/* @ts-ignore */}
          <Paper
            variant="outlined"
            severity="info"
            align="center"
            sx={{ mt: 8, pt: 1, pb: 1, pl: 2, pr: 2 }}
          >
            <Link to="/pricing" component={RouterLink}>
              プロアカウント
            </Link>
            ならコピーペーストの手間なく一発で翻訳可能
            <br />
            <Typography sx={{ fontWeight: "bold", color: "#1976d2" }}>
              1 週間無料体験実施中！
            </Typography>
          </Paper>
        </Box>
      </Container>
      <input
        hidden
        type="file"
        onChange={onFileInputChange}
        accept={/iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? undefined : '.pdf'}
        ref={inputRef}
      />
      <Snackbar
        open={showSnack}
        autoHideDuration={20000}
        onClose={() => setShowSnack(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowSnack(false)}
          // @ts-ignore
          severity={snackSeverity}
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default Free;
