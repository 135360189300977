import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Container,
  Box,
  CssBaseline,
  Card,
  CardContent,
  CardActions,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import { SignInStatus } from "./states";

const plans = [
  {
    name: "free",
    id: "",
    title: "Readable Free",
    price: 0,
    free: true,
    toku: false,
    description: ["DeepL を使って翻訳"],
  },
  {
    name: "monthly",
    title: "Readable Pro (月払)",
    price: "980",
    per: "月",
    free: false,
    toku: false,
    description: [
      "ワンクリック翻訳",
      "コピーアンドペーストの手間なし",
      "翻訳回数無制限",
      "100 ページ 50 MB まで一度に翻訳可能",
      "翻訳されたファイルが出来上がるまで約10秒",
      "Chrome 拡張機能のオンラインファイル翻訳",
      "独自の翻訳エンジンを使用",
      "DeepL の契約不要",
    ],
  },
  {
    name: "yearly",
    title: "Readable Pro (年払)",
    price: "9,800",
    per: "年",
    free: false,
    toku: true,
    description: [
      "ワンクリック翻訳",
      "コピーアンドペーストの手間なし",
      "翻訳回数無制限",
      "100 ページ 50 MB まで一度に翻訳可能",
      "翻訳されたファイルが出来上がるまで約10秒",
      "Chrome 拡張機能のオンラインファイル翻訳",
      "独自の翻訳エンジンを使用",
      "DeepL の契約不要",
    ],
  },
];

interface Props {
  signInStatus: SignInStatus;
}

const Pricing = (props: Props) => {
  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          fontWeight="fontWeightBold"
          sx={{ mb: 4 }}
        >
          プランを選択
        </Typography>
        <Grid container spacing={5} alignItems="stretch">
          {plans.map((plan) => {
            return (
              <Grid item key={plan.name} xs={12} lg={4} rowSpacing={8}>
                <Card>
                  <Typography
                    component="h2"
                    variant="h4"
                    color="text.primary"
                    align="center"
                    sx={{ mt: 2, mb: 8 }}
                  >
                    {plan.title}
                  </Typography>
                  <CardContent>
                    {plan.toku && (
                      <Typography
                        component="h2"
                        variant="h6"
                        color="info.main"
                        style={{ position: "relative", top: -20, height: 0 }}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        ￥1,960 お得
                      </Typography>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 8,
                      }}
                    >
                      <Typography
                        component="h2"
                        variant="h4"
                        color="text.primary"
                        style={{ position: "relative", top: -60 }}
                      >
                        ￥
                      </Typography>
                      <Typography
                        component="h2"
                        variant="h1"
                        color="text.primary"
                        sx={{ fontWeight: "medium" }}
                      >
                        {plan.price}
                      </Typography>
                      {plan.per && (
                        <Typography variant="h6" color="text.secondary">
                          /{plan.per}
                        </Typography>
                      )}
                    </Box>
                    {props.signInStatus !== SignInStatus.PREMIUM &&
                      !plan.free && (
                        <Typography
                          variant="body1"
                          color="info.main"
                          sx={{ fontWeight: "bold" }}
                        >
                          1 週間無料
                        </Typography>
                      )}
                    {plan.description.map((desc) => {
                      return (
                        <Typography
                          variant="body1"
                          color="text.secondary"
                          key={desc}
                        >
                          {desc}
                        </Typography>
                      );
                    })}
                  </CardContent>
                  <CardActions
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                    }}
                  >
                    {props.signInStatus !== SignInStatus.PREMIUM &&
                      plan.free && (
                        <Button
                          variant="contained"
                          to="/translate"
                          sx={{ mt: 28, mb: 4, width: 200, height: 40 }}
                          component={RouterLink}
                        >
                          翻訳はこちらから
                        </Button>
                      )}
                    {props.signInStatus !== SignInStatus.PREMIUM &&
                      !plan.free && (
                        <Button
                          variant="contained"
                          to={"/signup?plan=" + plan.name}
                          sx={{ mt: 4, mb: 4, width: 200, height: 40 }}
                          component={RouterLink}
                        >
                          登録へ進む
                        </Button>
                      )}
                    {props.signInStatus === SignInStatus.PREMIUM &&
                      plan.free && (
                        <Button
                          sx={{ mt: 25, mb: 4, width: 200, height: 40 }}
                          disabled
                        >
                          既にプロ会員です
                        </Button>
                      )}
                    {props.signInStatus === SignInStatus.PREMIUM &&
                      !plan.free && (
                        <Button
                          sx={{ mt: 4, mb: 4, width: 200, height: 40 }}
                          disabled
                        >
                          登録済みです
                        </Button>
                      )}
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
        {props.signInStatus !== SignInStatus.PREMIUM && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "baseline",
            }}
          >
            <Button
              variant="text"
              sx={{ mt: 5, mb: 2 }}
              to="/signup?plan=ticket"
              component={RouterLink}
            >
              アクティベーションコードをお持ちの方はこちら
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default Pricing;
