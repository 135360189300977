import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Link,
  Container,
  Box,
  CssBaseline,
  TextField,
  Grid,
  Button,
  Snackbar,
  Alert,
  Typography,
} from "@mui/material";
import firebase from "firebase/compat/app";

const REACT_APP_READABLE_URL: string = process.env.REACT_APP_READABLE_URL!;

const SignIn = () => {
  const [showSnack, setShowSnack] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState("error");
  const [snackMessage, setSnackMessage] = useState<any>();
  const [done, setDone] = useState(false);
  const [email, setEmail] = useState<string>();
  const handlReset = (e: any) => {
    e.preventDefault();
    const email = e.target.email.value;
    firebase
      .auth()
      .sendPasswordResetEmail(email, {
        url: REACT_APP_READABLE_URL,
      })
      .then(() => {
        setDone(true);
        setEmail(email);
      })
      .catch(() => {
        setSnackMessage("メールアドレスが見つかりませんでした。");
        setSnackSeverity("error");
        setShowSnack(true);
      });
  };
  const handleResubimt = () => {
    firebase
      .auth()
      // @ts-ignore
      .sendPasswordResetEmail(email, {
        url: process.env.REACT_APP_READABLE_URL,
      })
      .then()
      .then(() => {
        setSnackMessage("確認メールを再送しました。");
        setSnackSeverity("info");
        setShowSnack(true);
      })
      .catch(() => {
        setSnackMessage("エラーが発生しました。");
        setSnackSeverity("error");
        setShowSnack(true);
      });
  };
  if (done) {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Typography
          component="h2"
          variant="body1"
          color="text.primary"
          align="center"
          sx={{ mt: 4 }}
        >
          {email} に確認メールを送信しました。
        </Typography>
        <Typography
          component="h2"
          variant="body1"
          color="text.primary"
          align="center"
          sx={{ mb: 2 }}
        >
          確認メールのリンクをクリックしてください。
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleResubimt}
          >
            再送信
          </Button>
        </Box>
        <Snackbar
          open={showSnack}
          autoHideDuration={6000}
          onClose={() => setShowSnack(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => setShowSnack(false)}
            // @ts-ignore
            severity={snackSeverity}
            sx={{ width: "100%" }}
          >
            {snackMessage}
          </Alert>
        </Snackbar>
      </Container>
    );
  }
  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          fontWeight="fontWeightBold"
        >
          パスワードのリセット
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handlReset}
          sx={{
            mt: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextField
            fullWidth
            id="email"
            label="メールアドレス"
            name="email"
            autoComplete="email"
            sx={{ width: 500 }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            送信
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link variant="body2" to="/login" component={RouterLink}>
                ログイン
              </Link>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link variant="body2" to="/pricing" component={RouterLink}>
                会員登録
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Snackbar
        open={showSnack}
        autoHideDuration={6000}
        onClose={() => setShowSnack(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowSnack(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SignIn;
