import React, { useState, useRef, Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  Container,
  Stack,
  Typography,
  CircularProgress,
  Alert,
  CssBaseline,
  Box,
  Snackbar,
  Paper,
} from "@mui/material";
import axios from "axios";
import { INITIALSTATE, PREMIUM, SignInStatus } from "./states";
import AltButton from "./altButton";

const REACT_APP_READABLE_FILE_URL: string =
  process.env.REACT_APP_READABLE_FILE_URL!;

interface Props {
  user: any;
  signInStatus: SignInStatus;
  expire: number;
}

const Pro = (props: Props) => {
  const inputRef = useRef<any>(null);
  const [showSnack, setShowSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState<any>();
  const [state, setState] = useState(0);
  const [jaFilepath, setJaFilepath] = useState<string>();
  const [alFilepath, setAlFilepath] = useState<string>();
  const [prFilepath, setPrFilepath] = useState<string>();
  const processFile = (source: File) => {
    if (!source) {
      return;
    }
    setState(1);
    const params = new FormData();
    params.append("file", source);
    params.append("token", props.user.multiFactor.user.accessToken);
    params.append("original_filename", source.name);
    axios
      .post(process.env.REACT_APP_READABLE_BACKEND_URL + "auto/", params)
      .then((response) => {
        if (response.status !== 200) {
          setSnackMessage(
            "予期せぬエラーが発生しました。お送りいただいた文書が Readable に対応していない可能性があります。"
          );
          setState(0);
          setShowSnack(true);
        } else if ("sizeover" in response.data) {
          setSnackMessage(
            "ファイルサイズが大きすぎます。100 ページかつ 50 MB 以内に分割してご利用ください。"
          );
          setState(0);
          setShowSnack(true);
        } else if ("undefined" in response.data) {
          setState(0);
        } else if ("error" in response.data) {
          setSnackMessage(
            "予期せぬエラーが発生しました。お送りいただいた文書が Readable に対応していない可能性があります。"
          );
          setState(0);
          setShowSnack(true);
        } else if ("notext" in response.data) {
          setSnackMessage(
            "テキストが検出されませんでした。PDF ファイルのテキストをマウスでドラッグするなどしてテキストが選択できるか・埋め込まれているかを確認してください。PDF を分割した場合、分割の過程でテキスト情報が失われた可能性があります。適切に分割してください。元のファイルにテキスト情報がない場合は Readable は利用できません。断念するか、テキストが埋め込まれている同等のファイル・論文を見つけて利用ください。"
          );
          setState(0);
          setShowSnack(true);
        } else if ("notpdf" in response.data) {
          setSnackMessage("PDF ファイルにのみ対応しております。");
          setState(0);
          setShowSnack(true);
        } else {
          setJaFilepath(response.data.ja);
          setAlFilepath(response.data.alt);
          setPrFilepath(response.data.print);
          setState(2);
        }
      })
      .catch((e) => {
        if (e.response.status === 413) {
          setSnackMessage(
            "ファイルサイズが大きすぎます。100 ページかつ 50 MB 以内に分割してご利用ください。"
          );
          setState(0);
          setShowSnack(true);
        } else {
          setSnackMessage(
            "予期せぬエラーが発生しました。お送りいただいた文書が Readable に対応していない可能性があります。"
          );
          setState(0);
          setShowSnack(true);
        }
      });
  };
  const onDrop = (e: DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    // @ts-ignore
    const files = e.dataTransfer.files;
    if ((state === 0 || state >= 2) && files.length === 1 && files[0]) {
      processFile(files[0]);
    }
  };
  const onDragOver = (e: DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const onFileInputChange = (e: any) => {
    const source = e.target.files[0];
    if (!source) {
      return;
    }
    processFile(source);
  };
  if (props.signInStatus === INITIALSTATE) {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
      </Container>
    );
  }
  if (props.signInStatus !== PREMIUM) {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Typography
          component="h2"
          variant="body1"
          color="text.primary"
          align="center"
          sx={{ mt: 4 }}
        >
          アカウントが登録されていません。
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
          }}
        >
          <Button
            variant="contained"
            to="/pricing"
            sx={{ mt: 4, width: 200, height: 40 }}
            component={RouterLink}
          >
            登録はこちらから
          </Button>
        </Box>
      </Container>
    );
  }
  return (
    <Fragment>
      {/* @ts-ignore */}
      <Container
        component="main"
        sx={{ mb: 2 }}
        maxWidth="lg"
        onDragOver={onDragOver}
        onDrop={onDrop}
      >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            component="h1"
            fontWeight="fontWeightBold"
            sx={{ mb: 2 }}
          >
            1.翻訳したいPDFをアップロード
          </Typography>
          <img src="./imgs/upload.png" alt="アップロード" width="200" />
          {(state === 0 || state >= 2) && (
            <Button
              variant="contained"
              sx={{ mt: 2, mb: 4, width: 200, height: 40 }}
              onClick={() => {
                inputRef.current.click();
              }}
            >
              PDFのアップロード
            </Button>
          )}
          {state === 1 && (
            <CircularProgress sx={{ mt: 2, mb: 4, height: 40 }} />
          )}
          <Typography
            variant="h4"
            component="h1"
            fontWeight="fontWeightBold"
            sx={{ mb: 4 }}
          >
            2.翻訳結果をダウンロード
          </Typography>
          {state < 2 && (
            <img
              src="./imgs/download_disable.png"
              alt="翻訳結果をダウンロード"
              width="300"
            />
          )}
          {state >= 2 && (
            <img
              src="./imgs/download.png"
              alt="翻訳結果をダウンロード"
              width="300"
            />
          )}
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ mt: 4 }}
          >
            {state < 2 && (
              <Button variant="contained" disabled>
                日本語 PDF のダウンロード
              </Button>
            )}
            {state >= 2 && (
              <Button
                variant="contained"
                onClick={() => {
                  window.open(
                    REACT_APP_READABLE_FILE_URL + jaFilepath,
                    "_blank"
                  );
                }}
              >
                日本語 PDF のダウンロード
              </Button>
            )}
            <AltButton
              disable={state < 2}
              // @ts-ignore
              alFilepath={alFilepath}
              // @ts-ignore
              prFilepath={prFilepath}
            />
          </Stack>
          <Paper variant="outlined" sx={{ mt: 4, mb: 3, p: 3, width: 600 }}>
            <Typography component="h4">
              交互 PDF がおすすめです。交互 PDF
              は日本語のページと英語のページが交互に現れます。Chrome の PDF
              ビューワーであれば、右上の三点ボタンから 2
              ページ表示を選んで見開き表示してください。
            </Typography>
            <Typography component="h4">
              プリンタ印刷用の交互 PDF
              では冒頭に空ページが挿入されています。プリンタで両面印刷すると見開きで読むことができます。
            </Typography>
          </Paper>
          <Paper variant="outlined" sx={{ mt: 3, mb: 3, p: 3, width: 600 }}>
            <Typography component="h4">
              拡張機能により Readable
              にアクセスしなくてもブラウザのボタンから翻訳リクエストを投げられるようになります。
            </Typography>
            <Typography component="h4">
              <a
                href="https://chrome.google.com/webstore/detail/readable/pmhcplemclcflofgnjfhoilpkclnjnfh?hl=ja"
                target="_blank"
                rel="noreferrer"
              >
                Chrome 拡張機能をインストール
              </a>
              する。
            </Typography>
            <Typography component="h4">
              拡張機能をピン止めすると便利です。
            </Typography>
            <Typography component="h4">使い方</Typography>
            <ol>
              <li>
                Chrome で PDF
                のページを開く（ローカルファイルには対応していません。）
              </li>
              <li>拡張機能のアイコンが青色になるのでクリックする</li>
              <li>（ログインしていない場合はログインしてやり直し）</li>
              <li>拡張機能のアイコンが薄青の ... になるので待つ</li>
              <li>しばらくすると新しいタブで翻訳済みファイルが開く</li>
            </ol>
          </Paper>
          {props.expire !== 0 && (
            <Typography component="h4">
              プロアカウントの有効期限は{" "}
              {new Date(props.expire * 1000).getFullYear()} 年{" "}
              {new Date(props.expire * 1000).getMonth() + 1} 月{" "}
              {new Date(props.expire * 1000).getDate()} 日です。
            </Typography>
          )}
        </Box>
      </Container>
      <input
        hidden
        type="file"
        onChange={onFileInputChange}
        accept={/iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? undefined : '.pdf'}
        ref={inputRef}
      />
      <Snackbar
        open={showSnack}
        autoHideDuration={6000}
        onClose={() => setShowSnack(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowSnack(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default Pro;
