import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, CssBaseline, Box, Typography } from "@mui/material";
import axios from "axios";

const Thanks = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const intervalId = setInterval(() => {
      clearInterval(intervalId);
      navigate("/translate");
    }, 2000);
    return () => clearInterval(intervalId);
  }, [navigate]);
  useEffect(() => {
    axios.post(
      process.env.REACT_APP_READABLE_BACKEND_URL +
        "log?message=pro_registration",
      {},
      { withCredentials: true }
    );
  }, []);
  return (
    <Container component="main" sx={{ mb: 2 }} maxWidth="lg">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" component="h2" align="center">
          ご登録ありがとうございます。翻訳ページに遷移します。
        </Typography>
      </Box>
    </Container>
  );
};

export default Thanks;
